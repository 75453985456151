import { generatePath, Link, useParams } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import {
    Bookmarks,
    BoxArrowRight,
    CodeSlash,
    Display,
    ExclamationTriangleFill,
    Eye,
    Gear,
    List,
    ShieldShaded,
    Sliders,
} from 'react-bootstrap-icons';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { EDIT_PATH } from 'scenes/PublicationsV2';
import Constants, { Permissions } from '../../../../config/Constants';
import RestrictedContent from '../../../global/RestrictedContent';
import { PublicationProperty, templateProperties } from '../../forms/PublicationProperty';
import { useTranslation } from 'react-i18next';
import { FiLock } from 'react-icons/fi';
import { useGetLabelFolders } from 'pages/labels/hooks/useGetLabelFolders';
import { entityTypes } from 'pages/translation/config/Constants';

export default function Sidebar() {
    const labelFolders = useGetLabelFolders(entityTypes.PUBLICATION);
    const { publicationId, action, groupId } = useParams();
    const { errors, values } = useFormikContext();
    const { t } = useTranslation('publications');

    const { type } = values;
    const isDefaultType = type === Constants.publication.type.default;

    return (
        <div className="py-4">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <span className="text-color font-weight-bold">{t('publication.settings.contentSidebar.settings')}</span>
            </div>

            <Nav className="sidebar-nav flex-column font-size-sm">
                <Link
                    to={generatePath(EDIT_PATH, {
                        publicationId,
                        action: 'properties',
                        groupId,
                    })}
                    className={cx('nav-link d-flex align-items-center mt-0', {
                        active: action === 'properties',
                    })}
                >
                    <Gear size={14} className="mr-1" />
                    {t('publication.settings.contentSidebar.features')}
                    {(_.has(errors, 'name') || _.has(errors, 'title')) && <ErrorIcon />}
                </Link>

                {isDefaultType && (
                    <>
                        <Link
                            to={generatePath(EDIT_PATH, {
                                publicationId,
                                action: 'compose',
                                groupId,
                            })}
                            className={cx('nav-link d-flex align-items-center', {
                                active: action === 'compose',
                            })}
                        >
                            <List size={14} className="mr-1" />
                            {t('publication.settings.contentSidebar.summary')}
                        </Link>

                        <Link
                            to={generatePath(EDIT_PATH, {
                                publicationId,
                                action: 'layout',
                                groupId,
                            })}
                            className={cx('nav-link d-flex align-items-center', {
                                active: action === 'layout',
                            })}
                        >
                            <Sliders size={14} className="mr-1" />
                            {t('publication.settings.contentSidebar.layout')}
                            {(_.has(errors, 'templateId') ||
                                _.has(errors, 'domainId') ||
                                _.has(errors, 'pdfFileName') ||
                                _.has(errors, 'htmlSlug') ||
                                _.has(errors, 'pdfPermissionPassword')) && <ErrorIcon />}
                        </Link>

                        <Link
                            to={generatePath(EDIT_PATH, {
                                publicationId,
                                action: 'preview',
                                groupId,
                            })}
                            className={cx('nav-link d-flex align-items-center', {
                                active: action === 'preview',
                            })}
                        >
                            <Eye size={14} className="mr-1" />
                            {t('publication.settings.contentSidebar.example')}
                        </Link>

                        <Link
                            to={generatePath(EDIT_PATH, {
                                publicationId,
                                action: 'export_changes',
                                groupId,
                            })}
                            className={cx('nav-link d-flex align-items-center', {
                                active: action === 'export_changes',
                            })}
                        >
                            <BoxArrowRight size={14} className="mr-1" />
                            {t('publication.settings.contentSidebar.exportChanges')}
                        </Link>
                        <RestrictedContent module={Constants.modules.publication_access_control}>
                            <Link
                                to={generatePath(EDIT_PATH, {
                                    publicationId,
                                    action: 'password_protection',
                                    groupId,
                                })}
                                className={cx('nav-link d-flex align-items-center', {
                                    active: action === 'password_protection',
                                })}
                            >
                                <FiLock size={14} className="mr-1" />
                                {t('publication.settings.contentSidebar.password_protection')}
                            </Link>
                        </RestrictedContent>
                        <RestrictedContent module={Constants.modules.reimbursements}>
                            <PublicationProperty property={templateProperties.xmlProperties.enableXml}>
                                <Link
                                    to={generatePath(EDIT_PATH, {
                                        publicationId,
                                        action: 'reimbursement_xml',
                                        groupId,
                                    })}
                                    className={cx('nav-link d-flex align-items-center', {
                                        active: action === 'reimbursement_xml',
                                    })}
                                >
                                    <CodeSlash size={14} className="mr-1" />
                                    {t('publication.settings.contentSidebar.reimbursementXml')}
                                </Link>
                            </PublicationProperty>
                        </RestrictedContent>
                        <RestrictedContent permission={Permissions.Publication['Write.All']}>
                            <Link
                                to={generatePath(EDIT_PATH, {
                                    publicationId,
                                    action: 'kiosk',
                                    groupId,
                                })}
                                className={cx('nav-link d-flex align-items-center', {
                                    active: action === 'kiosk',
                                })}
                            >
                                <Display size={14} className="mr-1" />
                                {t('publication.settings.contentSidebar.kiosk')}
                            </Link>
                        </RestrictedContent>
                    </>
                )}

                <RestrictedContent module={Constants.modules.guard}>
                    <Link
                        to={generatePath(EDIT_PATH, {
                            publicationId,
                            action: 'guard',
                            groupId,
                        })}
                        className={cx('nav-link d-flex align-items-center', {
                            active: action === 'guard',
                        })}
                    >
                        <ShieldShaded size={14} className="mr-1" />
                        {t('publications:guard.guardIntegrity')}
                    </Link>
                </RestrictedContent>

                {labelFolders.length > 0 && (
                    <Link
                        to={generatePath(EDIT_PATH, {
                            publicationId,
                            action: 'labels',
                            groupId,
                        })}
                        className={cx('nav-link d-flex align-items-center', {
                            active: action === 'labels',
                        })}
                    >
                        <Bookmarks size={14} className="mr-1" />
                        {t('publication.settings.contentSidebar.labels')}
                    </Link>
                )}
            </Nav>
        </div>
    );
}

function ErrorIcon() {
    const { t } = useTranslation('publications');

    return (
        <ExclamationTriangleFill
            className="text-danger ml-auto"
            data-uk-tooltip={t('publication.settings.contentSidebar.errors')}
        />
    );
}
