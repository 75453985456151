import { ReEvaluationIcon, TaskHistory } from 'pages/task_team/modals/view_task_modal/TaskHistory';
import { TaskFiles } from 'pages/task_team/modals/view_task_modal/TaskFiles';
import { TaskVersions } from 'pages/task_team/modals/view_task_modal/TaskVersions';
import { useIsTaskOwner } from 'pages/task_team/hooks/permissions';
import { fileType, taskStatus, taskVersionStatus } from 'pages/task_team/config/constants';
import { DeadlineWarningIcon } from 'pages/task_team/modals/view_task_modal/TaskProperties';
import { useSubmitTaskVersionMutation } from 'features/comments/commentApi';
import { DateTime } from 'luxon';
import { LightOutlineButton, WarningButton } from 'components/Buttons';
import { Alert } from 'react-bootstrap';
import { TaskVersionProperties } from 'pages/task_team/modals/view_task_modal/TaskVersionProperties';
import { useState } from 'react';
import { useGetTaskVersion } from 'pages/task_team/hooks/useGetTaskVersion';
import { useGetTaskVersionAttachments } from 'pages/task_team/hooks/useGetTaskVersionAttachments';

export function TaskReviews({ task }) {
    return (
        <TaskHistory task={task}>
            {({ selectedVersion }) => (
                <div>
                    <VersionHeader task={task} selectedVersion={selectedVersion} />

                    {task?.status === taskStatus.STATUS_PAUSED && (
                        <Alert variant="danger">Het beoordelen van deze uiting is gepauzeerd.</Alert>
                    )}

                    <TaskFiles task={task} selectedVersion={selectedVersion} />
                    <TaskVersions task={task} selectedVersion={selectedVersion} />
                    <TaskVersionProperties task={task} selectedVersion={selectedVersion} />
                </div>
            )}
        </TaskHistory>
    );
}

function VersionHeader({ task, selectedVersion }) {
    const version = useGetTaskVersion(_.get(selectedVersion, '@id'));
    const isTaskOwner = useIsTaskOwner(task);

    return (
        <div className="flex-center justify-content-between border-bottom mb-4 pb-1">
            <div className="flex-center font-weight-bold" style={{ fontSize: 14 }}>
                Versie #{selectedVersion?.versionNumber}
                {version?.status === taskVersionStatus.STATUS_DRAFT && (
                    <span className="text-muted">&nbsp;-&nbsp;concept</span>
                )}
                <ReEvaluationIcon version={version} className="ml-2" />
            </div>

            {isTaskOwner && version?.status === taskVersionStatus.STATUS_DRAFT && (
                <SubmitVersionButton task={task} version={version} />
            )}
        </div>
    );
}

function SubmitVersionButton({ task, version }) {
    const [submitTaskVersion] = useSubmitTaskVersionMutation();
    const [showConfirm, setShowConfirm] = useState(false);
    const versionFilesAreValid = useVersionFilesAreValid(version);

    const deadline = DateTime.fromISO(version.deadline);
    const now = DateTime.now();
    const deadlineIsValid = deadline && deadline > now;

    const canSubmit = deadlineIsValid && versionFilesAreValid;

    if (showConfirm) {
        return (
            <div className="flex-center">
                <LightOutlineButton size="sm" className="mr-2" onClick={() => setShowConfirm(false)}>
                    Annuleer
                </LightOutlineButton>
                <WarningButton size="sm" onClick={() => submitTaskVersion({ uri: version['@id'] })}>
                    Indienen bevestigen
                </WarningButton>
            </div>
        );
    }

    return (
        <div>
            <DeadlineWarningIcon task={task} version={version} className="mr-2" />
            <WarningButton size="sm" disabled={!canSubmit} onClick={() => setShowConfirm(true)}>
                Versie {version.versionNumber} indienen&hellip;
            </WarningButton>
        </div>
    );
}

export function useVersionFilesAreValid(version) {
    const attachments = useGetTaskVersionAttachments(_.get(version, ['@id']));
    const files = attachments.filter((attachment) => attachment.type === fileType.TYPE_ATTACHMENT);

    return (version && !_.isEmpty(version.content)) || files.length > 0;
}
