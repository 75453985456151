import { Modal } from 'react-bootstrap';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { closeModal } from 'features/publications/publicationsSlice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAddExternalVersionMutation } from 'features/publications/publicationApi';
import { FormFieldWithTitle, Switch } from 'pages/publications_v2/helpers/FieldHelper';

export function AddExternalVersionModal() {
    const dispatch = useDispatch();
    const { t } = useTranslation('publications');
    const { addExternalVersion } = useSelector((state) => state.publications.modals);

    const payload = addExternalVersion;
    const showModal = !!addExternalVersion;

    const { publication } = payload;

    return (
        <FormModal show={showModal} onHide={handleClose} title={getTitle()}>
            {showModal && <PublishModalBody payload={payload} handleClose={handleClose} />}
        </FormModal>
    );

    function getTitle() {
        if (!showModal) {
            return '';
        }

        return t('publications.bulkActions.publishModal.addVersionTitle', { name: publication.name });
    }

    function handleClose() {
        dispatch(closeModal('addExternalVersion'));
    }
}

function PublishModalBody({ payload, handleClose }) {
    const { t } = useTranslation('publications');
    const [addExternalVersion] = useAddExternalVersionMutation();

    return (
        <Formik
            initialValues={{
                publication: payload.publication['@id'],
                pdf: true,
                pdfUrl: '',
                html: false,
                htmlUrl: '',
            }}
            validationSchema={PublishSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, dirty, values }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <FormContent values={values} />
                    </Modal.Body>

                    <ModalFooter
                        isValid={isValid && (values.pdf || values.html)}
                        dirty={dirty}
                        isSubmitting={isSubmitting}
                        onHide={handleClose}
                    />
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values) {
        addExternalVersion({ publicationUri: values.publication, body: values }).then(() => {
            handleClose();
        });
    }
}

function FormContent({ values }) {
    return (
        <div>
            <div className="mb-3">
                <Switch name="pdf" label="Pdf" />
                {values.pdf && <FormFieldWithTitle label="Pdf url" name="pdfUrl" props={{ required: true }} />}
            </div>

            <Switch name="html" label="Html" />
            {values.html && <FormFieldWithTitle label="Html url" name="htmlUrl" props={{ required: true }} />}
        </div>
    );
}

const PublishSchema = Yup.object().shape({
    publication: Yup.string().required(),
    pdfUrl: Yup.string().nullable().when('pdf', {
        is: true,
        then: Yup.string().required(),
    }),
    htmlUrl: Yup.string().nullable().when('html', {
        is: true,
        then: Yup.string().required(),
    }),
});
