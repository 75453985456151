import { BooleanParam, JsonParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { NewItemButton, PrimaryButton, PrimaryLinkButton } from 'components/Buttons';
import { ReactTitle } from 'components/ReactTitle';
import { AddCheckModal } from 'pages/task_team/modals/AddCheckModal';
import { useGetTasks } from 'pages/task_team/hooks/useGetTasks';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { ArchiveFill, ArrowUpShort, CircleFill, ExclamationTriangleFill, ShieldLock } from 'react-bootstrap-icons';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { DateTime, Interval } from 'luxon';
import { useTranslation } from 'react-i18next';
import { ChecksSidebar } from 'pages/task_team/views/checks/ChecksSidebar';
import { generatePath, useParams } from 'react-router-dom';
import { ViewTaskModal } from 'pages/task_team/modals/ViewTaskModal';
import { Reviews } from 'pages/task_team/views/checks/Reviews';
import { AssignTaskModal } from 'pages/task_team/modals/AssignTaskModal';
import { defaultFilterAllOptions, FilterAll } from 'pages/task_team/views/checks/filters/FilterAll';
import { FilterMy } from 'pages/task_team/views/checks/filters/FilterMy';
import { FilterAssignments } from 'pages/task_team/views/checks/filters/FilterAssignments';
import { Unassigned } from 'pages/task_team/views/checks/Unassigned';
import { FilterUnassigned } from 'pages/task_team/views/checks/filters/FilterUnassigned';
import cx from 'classnames';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { taskStatus, taskVersionStatus } from 'pages/task_team/config/constants';
import {
    useCanChangeTaskOwner,
    useCanOverrideTaskStatus,
    useHasCreateTaskPermission,
} from 'pages/task_team/hooks/permissions';
import { ChangeTaskOwnerModal } from 'pages/task_team/modals/ChangeTaskOwnerModal';
import {
    EmptyState,
    EmptyStateActions,
    EmptyStateBody,
    EmptyStateHeader,
    EmptyStateIcon,
    RocketIcon,
} from 'components/EmptyState';
import { CHECKS_PATH, PAGINATION_ITEMS_PER_PAGE } from 'scenes/TaskTeam';
import { MoreActionsDropDown } from 'components/DropdownButtons';
import { useDeleteTaskMutation, useUpdateTaskMutation } from 'features/comments/commentApi';
import { RecycleBin } from 'pages/task_team/views/checks/RecycleBin';
import { ApproveTaskModal } from 'pages/task_team/modals/ApproveTaskModal';
import { NewVersionModal } from 'pages/task_team/modals/NewVersionModal';
import HelperFunctions from 'pages/global/HelperFunctions';
import { TaskProgress } from 'pages/task_team/views/checks/TaskProgress';
import { TableRowSkeleton } from 'components/Skeleton';

const RenderPage = {
    all: AllChecks,
    my: MyChecks,
    review: Reviews,
    unassigned: Unassigned,
    'recycle-bin': RecycleBin,
};

const RenderFilters = {
    all: FilterAll,
    my: FilterMy,
    review: FilterAssignments,
    unassigned: FilterUnassigned,
};

export function Checks() {
    const { page = 'all' } = useParams();
    const PageToRender = RenderPage[page] ?? PageNotFound;
    const FiltersToRender = RenderFilters[page] ?? FilterNotFound;

    return (
        <>
            <ReactTitle title="Uitingen" />
            <AddCheckModal />
            <NewVersionModal />
            <ViewTaskModal />
            <ApproveTaskModal />
            <AssignTaskModal />
            <ChangeTaskOwnerModal />

            <Container fluid={true} className="overflow-auto px-5">
                <Row noGutters={true} style={{ maxWidth: 1400 }}>
                    <Col xs={3}>
                        <div className="py-4 pr-4">
                            <ChecksSidebar />

                            <div className="font-weight-bold mb-3">Filters</div>
                            <FiltersToRender />
                        </div>
                    </Col>
                    <Col xs={9}>
                        <div className="pl-4 py-4">
                            <div className="dr-container p-4">
                                <PageToRender />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

function AllChecks() {
    return (
        <div>
            <div className="subheader">
                <h3>Alle uitingen</h3>
                <NewTaskButton />
            </div>

            <TasksTable />
        </div>
    );
}

function MyChecks() {
    const { page = 'all' } = useParams();

    return (
        <div>
            <div className="subheader">
                <h3>Mijn aanvragen</h3>
                <NewTaskButton />
            </div>

            <TasksTable my={page === 'my'} />
        </div>
    );
}

const defaultSortOptions = {
    sequence: 'DESC',
};

function TasksTable({ my = false }) {
    const {
        items = [],
        totalItems = 0,
        isEmpty = false,
        isLoading = true,
    } = useGetTasks(my, defaultSortOptions, defaultFilterAllOptions);
    const [sortOptions = defaultSortOptions, setSortOptions] = useQueryParam('sort', JsonParam);

    return (
        <TableProvider>
            <PaginatedTable
                items={items}
                totalItems={totalItems}
                clientSidePagination={false}
                itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
                searchPlaceholder="Zoek op titel of nummer..."
                colSpan={my ? 7 : 6}
            >
                {({ items = [] }) => (
                    <>
                        <thead>
                            <tr>
                                {renderSortableHeader('#', 'sequence', 'DESC')}
                                {renderSortableHeader('Titel', 'title')}
                                {renderSortableHeader('Status', 'statusNL')}

                                <th>Voortgang</th>
                                {renderSortableHeader('Deadline', 'deadline')}
                                {renderSortableHeader('Onder embargo', 'confidential')}
                                {my && <th>Acties</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <TableRowSkeleton cellCount={my ? 7 : 6} />}

                            {isEmpty ? (
                                <tr>
                                    <EmptyChecks my={my} />
                                </tr>
                            ) : (
                                <>
                                    {items.map((task) => (
                                        <TaskRow task={task} my={my} key={`task-${task.id}`} />
                                    ))}
                                </>
                            )}
                        </tbody>
                    </>
                )}
            </PaginatedTable>
        </TableProvider>
    );

    function renderSortableHeader(label, field, defaultOrder = 'ASC') {
        const isActive = sortOptions.hasOwnProperty(field);
        const currentDirection = sortOptions[field] ? sortOptions[field] : defaultOrder;

        return (
            <th>
                <span
                    className={cx('cursor-pointer', {
                        'font-weight-bold': isActive,
                    })}
                    onClick={() => handleSortToggle(field, currentDirection === 'ASC' ? 'DESC' : 'ASC')}
                >
                    {label}&nbsp;
                    <ArrowUpShort
                        className={cx({
                            'rotate-180': currentDirection === 'DESC',
                            'text-muted': !isActive,
                        })}
                    />
                </span>
            </th>
        );
    }

    function handleSortToggle(field, defaultOrder = 'ASC') {
        const currentSort = sortOptions[field] ? sortOptions[field] : defaultOrder;

        setSortOptions({
            [field]: currentSort === 'ASC' ? 'DESC' : 'ASC',
        });
    }
}

function EmptyChecks({ my = false }) {
    const [, setAddCheck] = useQueryParam('addCheck', BooleanParam);

    if (my) {
        return (
            <td colSpan={7}>
                <EmptyState>
                    <EmptyStateHeader
                        titleText={'Geen aanvragen gevonden'}
                        icon={<EmptyStateIcon icon={RocketIcon} />}
                    />
                    <EmptyStateBody>
                        Je hebt nog geen aanvragen ingediend of probeer een andere zoekopdracht. Start een nieuwe
                        aanvraag om te beginnen.
                    </EmptyStateBody>
                    <EmptyStateActions>
                        <PrimaryButton onClick={() => setAddCheck(true)}>Nieuwe OKÉ-check</PrimaryButton>
                    </EmptyStateActions>
                </EmptyState>
            </td>
        );
    }

    return (
        <td colSpan={6}>
            <EmptyState>
                <EmptyStateHeader titleText={'Geen uitingen gevonden'} icon={<EmptyStateIcon icon={RocketIcon} />} />
                <EmptyStateBody>Probeer een andere zoekopdracht of reset de filters.</EmptyStateBody>
                <EmptyStateActions>
                    <PrimaryLinkButton
                        to={generatePath(CHECKS_PATH, {
                            page: 'all',
                        })}
                    >
                        Filters resetten
                    </PrimaryLinkButton>
                </EmptyStateActions>
            </EmptyState>
        </td>
    );
}

function TaskRow({ task, my = false }) {
    const [, setViewTask] = useQueryParam('viewTask', StringParam);
    const { t, i18n } = useTranslation();
    const createdByName = useGetUserFullName(task.createdBy, false);
    const [, setQuery] = useQueryParams({ tid: StringParam, addCheck: BooleanParam });

    const { confidential, status } = task;

    const createdAtDateTime = DateTime.fromISO(task.createdAt).setLocale(i18n.language);
    const createdAt = createdAtDateTime.toLocaleString(DateTime.DATE_MED);
    const createdAtFull = createdAtDateTime.toLocaleString(DateTime.DATE_FULL);

    return (
        <tr>
            <td>
                {task.sequence && (
                    <>
                        #{task.sequence}-{task.activeVersionNumber}
                    </>
                )}
            </td>
            <td>
                <div>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();

                            if (status === taskStatus.STATUS_DRAFT && task.activeVersion === null) {
                                setQuery({
                                    tid: task.id,
                                    addCheck: true,
                                });
                            } else {
                                setViewTask(task.id);
                            }
                        }}
                    >
                        {task.archived && (
                            <ArchiveFill className="flex-shrink-0 mr-2" size={14} data-uk-tooltip="Gearchiveerd" />
                        )}
                        {task.title}
                        {task.status === taskStatus.STATUS_DRAFT && <> - concept</>}
                    </a>
                </div>
                <div>
                    {!my && <>{createdByName}</>}
                    {status !== taskStatus.STATUS_DRAFT && (
                        <span className="text-muted">
                            {!my && <>&nbsp;&bull;&nbsp;</>}
                            <span data-uk-tooltip={`Ingediend op ${createdAtFull}`}>{createdAt}</span>
                        </span>
                    )}
                </div>
            </td>
            <td>
                <TaskStatusBadge task={task} />
            </td>
            <td>
                {!task.archived &&
                    [
                        taskStatus.STATUS_APPROVED,
                        taskStatus.STATUS_PAUSED,
                        taskStatus.STATUS_REJECTED,
                        taskStatus.STATUS_REVIEW_IN_PROGRESS,
                    ].includes(status) && <TaskProgress taskVersionUri={task.activeVersion} />}
            </td>
            <td>
                <TaskDeadline task={task} />
            </td>
            <td align="center">
                {confidential && <ShieldLock size={16} uk-tooltip={'Onder embargo'} className="text-danger" />}
            </td>
            {my && (
                <td className="actions">
                    <MyTaskActions task={task} />
                </td>
            )}
        </tr>
    );
}

function MyTaskActions({ task }) {
    const { t } = useTranslation();
    const [updateTask] = useUpdateTaskMutation();
    const [deleteTask] = useDeleteTaskMutation();
    const [, setApprove] = useQueryParam('approve', StringParam);
    const canChangeTaskOwner = useCanChangeTaskOwner();
    const canOverrideTaskStatus = useCanOverrideTaskStatus();
    const [, setChangeOwner] = useQueryParam('changeOwner', StringParam);

    if (task.deletedAt) {
        return null;
    }

    const { status } = task;

    const canPause = status === taskStatus.STATUS_REVIEW_IN_PROGRESS;
    const canArchive =
        !task.archived &&
        [taskStatus.STATUS_APPROVED, taskStatus.STATUS_REJECTED, taskStatus.STATUS_WITDRAWN].includes(status);
    const canWithdraw = status === taskStatus.STATUS_REVIEW_IN_PROGRESS;

    return (
        <div>
            <MoreActionsDropDown id={`task-${task.id}-actions`} onSelect={onSelect}>
                {canOverrideTaskStatus && (
                    <Dropdown.Item
                        eventKey="approve"
                        disabled={
                            status !== taskStatus.STATUS_REVIEW_IN_PROGRESS && status !== taskStatus.STATUS_REJECTED
                        }
                    >
                        Goedkeuren d.m.v. directiebesluit&hellip;
                    </Dropdown.Item>
                )}

                {canChangeTaskOwner && (
                    <Dropdown.Item eventKey="change-owner">Eigenaar aanpassen&hellip;</Dropdown.Item>
                )}

                {(canOverrideTaskStatus || canChangeTaskOwner) && <Dropdown.Divider />}

                {status === taskStatus.STATUS_PAUSED ? (
                    <Dropdown.Item eventKey="resume">Beoordelen hervatten</Dropdown.Item>
                ) : (
                    <Dropdown.Item eventKey="pause" disabled={!canPause}>
                        Beoordelen pauzeren
                    </Dropdown.Item>
                )}

                {task.archived ? (
                    <Dropdown.Item eventKey="unarchive">Uit archief halen</Dropdown.Item>
                ) : (
                    <Dropdown.Item eventKey="archive" disabled={!canArchive}>
                        Archiveren
                    </Dropdown.Item>
                )}
                <Dropdown.Item eventKey="withdraw" disabled={!canWithdraw}>
                    Intrekken&hellip;
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item eventKey="delete">
                    <span className="text-danger">Verwijderen&hellip;</span>
                </Dropdown.Item>
            </MoreActionsDropDown>
        </div>
    );

    function onSelect(event) {
        switch (event) {
            case 'archive':
                updateTask({
                    uri: task['@id'],
                    body: {
                        archived: true,
                    },
                });
                return;
            case 'unarchive':
                updateTask({
                    uri: task['@id'],
                    body: {
                        archived: false,
                    },
                });
                return;
            case 'approve':
                setApprove(task.id);
                return;
            case 'pause':
                updateTaskStatus(taskStatus.STATUS_PAUSED);
                return;
            case 'resume':
                updateTaskStatus(taskStatus.STATUS_REVIEW_IN_PROGRESS);
                return;
            case 'withdraw':
                HelperFunctions.confirmModal(
                    `${task.title} intrekken? Dit kan niet ongedaan gemaakt worden.`,
                    'danger',
                    false,
                )
                    .then(() => {
                        updateTaskStatus(taskStatus.STATUS_WITDRAWN);
                    })
                    .catch(() => {});
                return;
            case 'change-owner':
                setChangeOwner(task.id);
                return;
            case 'delete':
                HelperFunctions.confirmModal(
                    `${task.title} nu naar de prullenbak verplaatsen?`,
                    'danger',
                    false,
                    'Ja, verwijderen',
                )
                    .then(() => {
                        deleteTask(task['@id']);
                    })
                    .catch(() => {});
                return;
        }
    }

    function updateTaskStatus(newStatus) {
        updateTask({
            uri: task['@id'],
            body: {
                status: newStatus,
            },
        });
    }
}

export function TaskStatusBadge({ task }) {
    return (
        <div className={cx('d-flex align-items-center', getStatusVariant())}>
            <CircleFill size={9} className="flex-shrink-0 mr-2" />
            {getStatusCode(task)}
        </div>
    );

    function getStatusVariant() {
        if (task.deletedAt) {
            return 'text-danger';
        }

        switch (task.status) {
            case taskStatus.STATUS_REVIEW_IN_PROGRESS:
                return 'text-warning';
            case taskStatus.STATUS_APPROVED:
                return 'text-success';
            case taskStatus.STATUS_REJECTED:
                return 'text-danger';
            case taskStatus.STATUS_DRAFT:
            case taskStatus.STATUS_WITDRAWN:
                return 'text-muted';
        }
    }
}

export function getStatusCode(task) {
    if (task.deletedAt) {
        return 'Verwijderd';
    }

    if (task.status === taskStatus.STATUS_APPROVED && task.approvedByManagementDecision === true) {
        return 'Goedgekeurd (d.m.v. directiebesluit)';
    }

    if (task.status === taskStatus.STATUS_APPROVED && task.autoApproved === true) {
        return 'Goedgekeurd (deadline verstreken)';
    }

    switch (task.status) {
        case taskStatus.STATUS_REVIEW_IN_PROGRESS:
            return 'In behandeling';
        case taskStatus.STATUS_APPROVED:
            return 'Goedgekeurd';
        case taskStatus.STATUS_REJECTED:
            return 'Afgekeurd';
        case taskStatus.STATUS_DRAFT:
            return 'Concept';
        case taskStatus.STATUS_PAUSED:
            return 'Gepauseerd';
        case taskStatus.STATUS_WITDRAWN:
            return 'Ingetrokken';
    }
}

export function TaskVersionStatusBadge({ version }) {
    return (
        <div className={cx('d-flex align-items-center', getStatusVariant())}>
            <CircleFill size={10} className="mr-2" />
            {getVersionStatusCode(version)}
        </div>
    );

    function getStatusVariant() {
        switch (version.status) {
            case taskVersionStatus.STATUS_REVIEW_IN_PROGRESS:
                return 'text-warning';
            case taskVersionStatus.STATUS_APPROVED:
                return 'text-success';
            case taskVersionStatus.STATUS_REJECTED:
                return 'text-danger';
            case taskVersionStatus.STATUS_DRAFT:
                return 'text-secondary';
            case taskVersionStatus.STATUS_WITDRAWN:
                return 'text-muted';
        }
    }
}

export function getVersionStatusCode(version) {
    if (version.status === taskVersionStatus.STATUS_APPROVED && version.approvedByManagementDecision === true) {
        return 'Goedgekeurd (d.m.v. directiebesluit)';
    }

    if (version.status === taskVersionStatus.STATUS_APPROVED && version.autoApproved === true) {
        return 'Goedgekeurd (deadline verstreken)';
    }

    switch (version.status) {
        case taskVersionStatus.STATUS_REVIEW_IN_PROGRESS:
            return 'In behandeling';
        case taskVersionStatus.STATUS_APPROVED:
            return 'Goedgekeurd';
        case taskVersionStatus.STATUS_REJECTED:
            return 'Afgekeurd';
        case taskVersionStatus.STATUS_DRAFT:
            return 'Concept';
        case taskVersionStatus.STATUS_WITDRAWN:
            return 'Ingetrokken';
    }
}

export function TaskDeadline({ task }) {
    const { i18n } = useTranslation();

    if (!task.deadline) {
        return '-';
    }

    const deadline = DateTime.fromISO(task.deadline).setLocale(i18n.language);
    const deadlineFull = deadline.toLocaleString(DateTime.DATETIME_MED);

    const showIcon = task.status === taskStatus.STATUS_REVIEW_IN_PROGRESS;
    const showDeadlineDanger = showIcon && isShowDeadlineDanger();
    const showDeadlineWarning = showIcon && !showDeadlineDanger && isShowDeadlineWarning();

    return (
        <div className="d-flex align-items-center">
            {(showDeadlineDanger || showDeadlineWarning) && (
                <ExclamationTriangleFill
                    size={14}
                    className={cx('flex-shrink-0 mr-2', {
                        'text-warning': showDeadlineWarning,
                        'text-danger': showDeadlineDanger,
                    })}
                />
            )}
            <div
                className={cx({
                    'text-danger font-weight-bold': showDeadlineDanger,
                    'text-muted': task.status !== taskStatus.STATUS_REVIEW_IN_PROGRESS,
                })}
            >
                {deadlineFull}
            </div>
        </div>
    );

    function isShowDeadlineWarning() {
        const now = DateTime.now();

        if (deadline < now) {
            return true;
        }

        const i = Interval.fromDateTimes(now, deadline);

        return i.length('days') < 8;
    }

    function isShowDeadlineDanger() {
        const now = DateTime.now();

        if (deadline <= now) {
            return true;
        }

        const i = Interval.fromDateTimes(now, deadline);

        return i.length('days') <= 1;
    }
}

function NewTaskButton() {
    const [, setAddCheck] = useQueryParam('addCheck', BooleanParam);
    const hasCreateTaskPermission = useHasCreateTaskPermission();

    if (!hasCreateTaskPermission) {
        return null;
    }

    return (
        <div className="d-flex align-items-center ml-auto">
            <NewItemButton size="sm" label="Nieuwe OKÉ-check" onClick={() => setAddCheck(true)} />
        </div>
    );
}

function PageNotFound() {
    return <div>Page not found</div>;
}

function FilterNotFound() {
    return null;
}
