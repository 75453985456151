import { ArrowRepeat, Calendar3, ClockHistory, Plus, TrashFill } from 'react-bootstrap-icons';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { NumberParam, useQueryParam } from 'use-query-params';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { TaskVersionStatusBadge } from 'pages/task_team/views/checks/Checks';
import { IconButton } from 'components/Buttons';
import { taskVersionStatus } from 'pages/task_team/config/constants';
import { useDeleteTaskVersionMutation, useNewTaskVersionMutation } from 'features/comments/commentApi';
import cx from 'classnames';
import { useIsTaskOwner } from 'pages/task_team/hooks/permissions';
import { useMemo } from 'react';
import { useGetUserFullName } from 'hooks/useGetUserFullName';

export function TaskHistory({ task, children }) {
    const [newTaskVersion] = useNewTaskVersionMutation();
    const [selectedVersionNumber = task?.activeVersionNumber, setSelectedVersion] = useQueryParam(
        'version',
        NumberParam,
    );
    const isTaskOwner = useIsTaskOwner(task);

    const selectedVersion = useMemo(() => {
        if (task && selectedVersionNumber) {
            return task.taskVersions.find((version) => version.versionNumber === selectedVersionNumber);
        }

        return undefined;
    }, [selectedVersionNumber, task]);

    const latestVersion = task ? task.taskVersions[0] ?? undefined : undefined;
    const latestVersionStatus = latestVersion?.status ?? taskVersionStatus.STATUS_DRAFT;

    return (
        <Container fluid={true} className="p-0">
            <Row noGutters={true}>
                <Col sm={3} xl={3}>
                    <div className="task-modal-history bg-light h-100">
                        <div className="py-3">
                            <div className="d-flex align-items-center justify-content-between mb-3 pl-3 pr-2 pt-1">
                                <div className="d-flex align-items-center font-weight-bold text-primary">
                                    <ClockHistory className="mr-2" />
                                    Versies
                                </div>

                                {isTaskOwner && (
                                    <IconButton
                                        disabled={
                                            task?.archived ||
                                            latestVersionStatus === taskVersionStatus.STATUS_DRAFT ||
                                            latestVersionStatus === taskVersionStatus.STATUS_REVIEW_IN_PROGRESS
                                        }
                                        tooltip="Nieuwe versie"
                                        icon={<Plus className="text-primary" size={24} />}
                                        onClick={() => {
                                            newTaskVersion({ uri: task['@id'] }).then(({ data }) => {
                                                if (data) {
                                                    setSelectedVersion(data.versionNumber);
                                                }
                                            });
                                        }}
                                    />
                                )}
                            </div>

                            {task && <TaskHistoryInner task={task} isTaskOwner={isTaskOwner} />}
                        </div>
                    </div>
                </Col>
                <Col sm={9} xl={9}>
                    <div className="p-4">{children({ selectedVersion, selectedVersionNumber })}</div>
                </Col>
            </Row>
        </Container>
    );
}

function TaskHistoryInner({ task, isTaskOwner }) {
    const [selectedVersion = task.activeVersionNumber, setSelectedVersion] = useQueryParam('version', NumberParam);

    return (
        <Nav
            defaultActiveKey={selectedVersion}
            className="flex-column"
            onSelect={(eventKey) => setSelectedVersion(eventKey)}
        >
            {task.taskVersions.map((version) => (
                <TaskVersion
                    active={version.versionNumber === selectedVersion}
                    version={version}
                    isTaskOwner={isTaskOwner}
                    taskUri={task['@id']}
                    key={`task-version-${version.id}`}
                />
            ))}
        </Nav>
    );
}

function TaskVersion({ version, active = false, isTaskOwner = false, taskUri }) {
    const { t, i18n } = useTranslation();
    const createdByName = useGetUserFullName(version.createdBy, false);
    const [deleteTaskVersion] = useDeleteTaskVersionMutation();
    const [, setSelectedVersion] = useQueryParam('version', NumberParam);

    const createdAt = DateTime.fromISO(version.createdAt)
        .setLocale(i18n.language)
        .toLocaleString(DateTime.DATETIME_SHORT);

    const canDelete = isTaskOwner && version.status === taskVersionStatus.STATUS_DRAFT && version.versionNumber > 1;

    return (
        <Nav.Link eventKey={version.versionNumber} active={active}>
            <div className="flex-center justify-content-between">
                <div>
                    <div className="flex-center" style={{ fontSize: 14 }}>
                        Versie #{version.versionNumber}
                        <ReEvaluationIcon version={version} className="ml-2" />
                    </div>

                    <TaskVersionStatusBadge version={version} />

                    {version.status !== taskVersionStatus.STATUS_DRAFT && (
                        <>
                            <div className="d-flex align-items-start text-muted font-weight-normal mt-1">
                                <Calendar3 size={14} className="flex-shrink-0" style={{ marginTop: 2 }} />
                                <div className="ml-1">
                                    Ingediend op {createdAt} door {createdByName}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {canDelete && (
                    <div>
                        <IconButton
                            tooltip={`Versie #${version.versionNumber} verwijderen`}
                            icon={<TrashFill className="text-danger" />}
                            onClick={(e) => onDelete(e)}
                        />
                    </div>
                )}
            </div>
        </Nav.Link>
    );

    function onDelete(event) {
        event.stopPropagation();

        // Select the previous version
        if (active) {
            setSelectedVersion(version.versionNumber - 1);
        }

        // Delete the given version
        deleteTaskVersion({ uri: version['@id'], taskUri });
    }
}

export function ReEvaluationIcon({ version, className = '' }) {
    if (!version?.isReEvaluation) {
        return null;
    }

    return (
        <ArrowRepeat
            size={16}
            className={cx(className, {
                'text-warning': version.status === taskVersionStatus.STATUS_DRAFT,
                'text-muted': version.status !== taskVersionStatus.STATUS_DRAFT,
            })}
            data-uk-tooltip={'Is herbeoordeling'}
        />
    );
}
