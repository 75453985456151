import MainContentNav from '../../Navbar';
import React, { useCallback, useEffect } from 'react';
import Sidebar from './index/Sidebar';
import Publications from './index2/Publications';
import EditFolderModal from '../modals/EditFolderModal';
import Filters from './index/Filters';
import PublishModal from '../modals/PublishModal';
import { ExportChangesModal } from '../modals/ExportChangesModal';
import DuplicateModal from '../modals/DuplicateModal';
import _ from 'lodash';
import HelperFunctions from '../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';
import SubNav from './SubNav';
import Constants, { Permissions } from '../../../config/Constants';
import { useHasModuleEnabled, useHasPermission } from '../../global/RestrictedContent';
import cx from 'classnames';
import { KioskBulkEditModal } from '../modals/KioskBulkEditModal';
import { MovePublicationsModal } from '../modals/MovePublicationsModal';
import { useGetPublications } from 'pages/publications_v2/hooks/useGetPublications';
import { updateAbilityForPublications } from 'ability/Ability';
import { useAbility } from 'ability/useAbility';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useUserProfile } from 'hooks/useUserProfile';
import { publicationApi, useEditPublicationGroupsMutation } from 'features/publications/publicationApi';
import { EventListener } from '../helpers/EventListener';
import { GuardPublicationModal } from './guard/GuardPublicationModal';
import { GuardEventListener } from './guard/helpers/GuardEventListener';
import { AddExternalVersionModal } from 'pages/publications_v2/modals/AddExternalVersionModal';

export default function Index({ folderId, publicationId }) {
    const currentOrganisation = useCurrentOrganisation();
    const { t } = useTranslation('publications');
    const hasAccessControlModuleEnabled = useHasModuleEnabled(Constants.modules.publication_access_control);
    const hasGuardModuleEnabled = useHasModuleEnabled(Constants.modules.guard);
    const hasKioskPanelEnabled = useHasPermission(Permissions.Kiosk.Create);
    const [editPublicationGroups] = useEditPublicationGroupsMutation();
    const [getPublicationGroups] = publicationApi.endpoints.getPublicationGroups.useLazyQuerySubscription();

    const onGroupDragEndMemo = useCallback(onGroupDragEnd, []);
    const handleMoveMemo = useCallback(handleMove, []);

    const hasSubnav = hasAccessControlModuleEnabled || hasKioskPanelEnabled || hasGuardModuleEnabled;

    return (
        <>
            <EventListener />
            {currentOrganisation.guardConnected && <GuardEventListener />}
            <MainContentNav title={t('breadcrumb')} />

            {hasSubnav && <SubNav />}

            <div
                className={cx('content-static-body', {
                    'has-subnav': hasSubnav,
                })}
            >
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar overflow-auto">
                        <Sidebar folderId={folderId} handleMove={handleMoveMemo} onGroupDragEnd={onGroupDragEndMemo} />
                    </div>
                    <div className="flex-grow-1 overflow-auto" style={{ marginTop: 75 }}>
                        <div className="py-3 px-4">
                            <Publications
                                publicationId={publicationId}
                                folderId={folderId}
                                handleMove={handleMoveMemo}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={cx('content-static-filters px-4 py-3', {
                    'has-subnav': hasSubnav,
                })}
            >
                <Filters folderId={folderId} />
            </div>

            <EditFolderModal />
            <PublishModal />
            <AddExternalVersionModal />
            <ExportChangesModal />
            <DuplicateModal folderId={folderId} />
            <KioskBulkEditModal />
            <MovePublicationsModal />
            <GuardPublicationModal />
            <AbilityUpdate />
        </>
    );

    async function onGroupDragEnd(result) {
        const { destination, source } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const allGroups = await getAllGroups();
        const sourceGroup = source.droppableId === 'default' ? null : source.droppableId;
        const destinationGroup = destination.droppableId === 'default' ? null : destination.droppableId;

        // Sort within group
        if (sourceGroup === destinationGroup) {
            let newGroups = _.cloneDeep(
                allGroups
                    .filter((_group) => _group.parent === destinationGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder')),
            );
            newGroups = HelperFunctions.arrayMove(newGroups, source.index, destination.index);

            // Reset sort order
            const changes = newGroups.map((_group, index) => {
                return {
                    id: _group.id,
                    changes: {
                        sortOrder: index,
                    },
                };
            });

            // Update APi
            editPublicationGroups(changes);
        } else {
            // Remove from old group
            let oldGroups = _.cloneDeep(
                allGroups
                    .filter((_group) => _group.parent === sourceGroup && _group.rootLevel === false)
                    .sort(HelperFunctions.dynamicSort('sortOrder')),
            );
            const [removed] = oldGroups.splice(source.index, 1);

            if (!removed) {
                return;
            }

            // Add to new group
            removed.parent = destinationGroup;
            let newGroups = _.cloneDeep(
                allGroups
                    .filter((_group) => _group.parent === destinationGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder')),
            );
            newGroups.splice(destination.index, 0, removed);

            newGroups = newGroups.map((_group, index) => {
                return {
                    ..._group,
                    sortOrder: index,
                };
            });

            const changes = newGroups.map((_group) => {
                return {
                    id: _group.id,
                    changes: {
                        parent: _group.parent,
                        sortOrder: _group.sortOrder,
                    },
                };
            });

            // Update APi
            editPublicationGroups(changes);
        }
    }

    function handleMove(direction, group, index) {
        if (direction === 'up' && index === 0) {
            return;
        }

        const payload = {
            draggableId: group.id,
            organisationId: currentOrganisation.id,
            source: {
                index,
                droppableId: group.parent,
            },
            destination: {
                index: direction === 'up' ? index - 1 : index + 1,
                droppableId: group.parent,
            },
        };

        onGroupDragEnd(payload);
    }

    async function getAllGroups() {
        const { data = [] } = await getPublicationGroups(undefined, true);

        return data.filter((_group) => _group.organisationId === currentOrganisation.id);
    }
}

function AbilityUpdate() {
    const currentOrganisation = useCurrentOrganisation();
    const ability = useAbility();
    const userProfile = useUserProfile();
    const allPublications = useGetPublications();

    const numberOfPublications = allPublications.length;

    useEffect(() => {
        updateAbilityForPublications(ability, numberOfPublications, userProfile, currentOrganisation);
    }, [numberOfPublications, userProfile, currentOrganisation]);

    return null;
}
