import { Permissions } from 'config/Constants';
import RestrictedContent from '../../global/RestrictedContent';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import cx from 'classnames';
import getEnv from '../../../config/Env';
import { useConnectMutation, useUpdateNotificationUsersMutation } from 'features/publications/guardApi';
import { useUpdateOrganisationMutation } from 'features/security/authApi';
import { PrimaryButton, WarningButton } from 'components/Buttons';
import { useContext, useState } from 'react';
import Spinner from '../../global/Spinner';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { DocRevSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';
import { FieldArray, Form as FForm, Formik, FormikContext } from 'formik';
import { useGetNotificationUsers } from 'pages/publications_v2/views/guard/hooks/useGetNotificationUsers';
import { useGetGuardApiToken } from 'pages/publications_v2/views/guard/hooks/useGetGuardApiToken';
import { useGetGuardUser } from 'pages/publications_v2/views/guard/hooks/useGetGuardUser';
import HelperFunctions from 'pages/global/HelperFunctions';
import { TableRowSkeleton } from 'components/Skeleton';

export function Guard() {
    const { t } = useTranslation();
    const currentOrganisation = useCurrentOrganisation();

    const isGuardConnected = currentOrganisation.guardConnected;

    return (
        <RestrictedContent permission={Permissions.GuardConfig.Create}>
            <div>
                <div className="subheader">
                    <h3>{t('publications:guard.guardIntegrity')}</h3>
                </div>
                <div className="row mb-3">
                    <div className="col-4">
                        <div className="font-weight-bold" style={{ color: '#344054', fontSize: 13 }}>
                            Status
                        </div>
                    </div>
                    <div className="col-8">
                        <div
                            className={cx('font-weight-bold', {
                                'text-success': isGuardConnected,
                                'text-danger': !isGuardConnected,
                            })}
                        >
                            {t(
                                isGuardConnected
                                    ? 'publications:guard.admin.connected'
                                    : 'publications:guard.admin.notConnected',
                            )}
                        </div>
                    </div>
                </div>
                {isGuardConnected ? <GuardSettings /> : <ConnectGuard organisation={currentOrganisation} />}
            </div>
        </RestrictedContent>
    );
}

function ConnectGuard({ organisation }) {
    const { t } = useTranslation();
    const environment = getEnv('DOCREV_ENV');
    const [connect] = useConnectMutation();
    const [updateOrganisation] = useUpdateOrganisationMutation();
    const [isSubmitting, setSubmitting] = useState(false);

    return (
        <div className="mt-3">
            <PrimaryButton className="flex-center" onClick={handleConnect} disabled={isSubmitting}>
                {isSubmitting && <Spinner variant="light" className="flex-shrink-0 mr-2" />}
                {isSubmitting
                    ? `${t('publications:guard.admin.connecting')}...`
                    : t('publications:guard.admin.connectTo')}
            </PrimaryButton>
        </div>
    );

    function handleConnect() {
        setSubmitting(true);

        connect({
            environment,
            organisationName: organisation.name,
            organisationId: organisation.id,
        }).then((result) => {
            if (result?.data?.token) {
                updateOrganisation({
                    id: organisation.id,
                    body: {
                        guardConnected: true,
                        guardApiToken: result.data.token,
                    },
                });
            } else {
                setSubmitting(false);
            }
        });
    }
}

function GuardSettings() {
    const guardUser = useGetGuardUser();

    if (!guardUser) {
        return null;
    }

    return (
        <>
            <Credits guardUser={guardUser} />
            <UserInfo guardUser={guardUser} />
            <NotificationSettings guardUser={guardUser} />
        </>
    );
}

function Credits({ guardUser }) {
    return (
        <div className="row mb-3">
            <div className="col-4">
                <div className="font-weight-bold pt-1" style={{ color: '#344054', fontSize: 13 }}>
                    Credits
                </div>
            </div>
            <div className="col-8">
                <div className="font-weight-bold pt-1">{guardUser.credits}</div>
            </div>
        </div>
    );
}

function UserInfo({ guardUser }) {
    return (
        <div className="row mb-4">
            <div className="col-4">
                <div className="font-weight-bold pt-1" style={{ color: '#344054', fontSize: 13 }}>
                    User ID
                </div>
            </div>
            <div className="col-8">
                <div className="pt-1">{guardUser.id}</div>
            </div>
        </div>
    );
}

function NotificationSettings({ guardUser }) {
    const { t } = useTranslation();
    const { notificationUsers } = useGetNotificationUsers();
    const [updateNotificationUsers] = useUpdateNotificationUsersMutation();
    const token = useGetGuardApiToken();
    const organisationUsers = useGetOrganisationUsers(true);

    return (
        <div>
            <div className="subheader">
                <h3>Notificaties</h3>
            </div>

            <Formik
                initialValues={{
                    notificationUsers: notificationUsers
                        .filter((notificationUser) => {
                            const user = organisationUsers.find((_user) => _user.id === notificationUser.userId);

                            return !!user;
                        })
                        .map((notificationUser) => {
                            const user = organisationUsers.find((_user) => _user.id === notificationUser.userId);

                            return {
                                ...notificationUser,
                                fullName: user.fullName,
                                user,
                            };
                        })
                        .sort(HelperFunctions.sortByString('fullName')),
                }}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ values, dirty, isSubmitting, isValid }) => (
                    <FForm autoComplete="off">
                        <div className="mb-4">
                            <NotificationUsers organisationUsers={organisationUsers} values={values} />
                        </div>

                        <div className="flex-center flex-row-reverse">
                            <WarningButton disabled={isSubmitting || !isValid || !dirty} type="submit">
                                {t('global:btn.save')}
                            </WarningButton>

                            {isSubmitting && <Spinner className="mr-3" />}
                        </div>
                    </FForm>
                )}
            </Formik>
        </div>
    );

    function handleSubmit(values, { setSubmitting }) {
        updateNotificationUsers({
            userId: guardUser.id,
            token,
            body: {
                id: guardUser.id,
                notificationUsers: values.notificationUsers.map((notificationUser) => ({
                    '@id': notificationUser['@id'],
                    id: notificationUser.id,
                    userId: notificationUser.userId,
                    email: notificationUser.user.email,
                })),
            },
        }).then(() => {
            setSubmitting(false);
        });
    }
}

function NotificationUsers({ organisationUsers = [], values }) {
    const userIds = values.notificationUsers.map((user) => user.userId);

    const availableUsers = organisationUsers
        .filter((_user) => userIds.includes(_user.id) === false)
        .map((_user) => {
            return {
                '@id': null,
                id: null,
                userId: _user.id,
                user: _user,
                label: _user.fullName,
                fullName: _user.fullName,
                value: _user.id,
            };
        });

    return (
        <FieldArray name="notificationUsers">
            {({ remove, push }) => (
                <div>
                    <Row className="mb-4">
                        <Col xs={4}>
                            <div className="font-weight-bold pt-1" style={{ color: '#344054', fontSize: 13 }}>
                                Gebruiker toevoegen
                            </div>
                        </Col>
                        <Col xs={8}>
                            <DocRevSelect
                                name="add_user"
                                options={availableUsers}
                                selectedValue={undefined}
                                props={{
                                    placeholder: 'Selecteer een gebruiker...',
                                }}
                                onChange={(selectedValue) => {
                                    if (selectedValue !== null) {
                                        push(selectedValue);
                                    }
                                }}
                            />
                        </Col>
                    </Row>

                    <SelectedUsers remove={remove} />
                </div>
            )}
        </FieldArray>
    );
}

function SelectedUsers({ remove }) {
    const { values } = useContext(FormikContext);
    const { isLoading, isEmpty } = useGetNotificationUsers();

    return (
        <Row>
            <Col xs={4}>
                <div className="font-weight-bold pt-1 mb-1" style={{ color: '#344054', fontSize: 13 }}>
                    Gebruikers
                </div>

                <div className="text-muted small">Deze gebruikers krijgen meldingen van de Integrity Guard</div>
            </Col>
            <Col xs={8}>
                <div className="border" style={{ borderRadius: '0.2rem' }}>
                    <table className="table table-team">
                        <thead>
                            <tr>
                                <th>Naam</th>
                                <th>Email</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && values.notificationUsers.length === 0 && <TableRowSkeleton />}

                            {!isLoading && values.notificationUsers.length === 0 && (
                                <tr>
                                    <td colSpan={3} className="text-muted">
                                        Er zijn nog geen gebruikers toegevoegd.
                                    </td>
                                </tr>
                            )}

                            {values.notificationUsers.map((notificationUser, index) => (
                                <UserRow
                                    notificationUser={notificationUser}
                                    remove={() => {
                                        remove(index);
                                    }}
                                    key={`user-row-${index}`}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </Col>
        </Row>
    );
}

function UserRow({ notificationUser, remove }) {
    const { user } = notificationUser;

    return (
        <tr>
            <td className="text-secondary">{user.fullName}</td>
            <td className="font-weight-bolder">{user.email}</td>
            <td className="align-middle">
                <div className="d-flex flex-row-reverse">
                    <a onClick={remove} className="font-weight-bold text-danger">
                        Verwijderen
                    </a>
                </div>
            </td>
        </tr>
    );
}
